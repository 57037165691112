/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}
.ProseMirror:focus {
  outline: none;
}

@media only screen and (min-width: 2400px) {
  Html {
    font-size: 18px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .paragraph-small {
    @apply text-sm text-gray-800;
  }
  .paragraph-regular {
    @apply text-base text-gray-800;
  }
  .paragraph-large {
    @apply text-lg text-gray-800;
  }
  .heading-xsmall {
    @apply text-base font-semibold;
  }
  .heading-small {
    @apply text-lg font-semibold;
  }
  .heading-regular {
    @apply text-xl font-semibold tracking-tight;
  }
  .heading-large {
    @apply text-2xl font-semibold tracking-tight;
  }

  .nav-button {
    @apply py-2.5 px-3 rounded-md transition-colors text-black duration-200 ease-in-out flex hover:bg-gray-300;
  }
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c5c5c5;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #7a7e82;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.tiptap {
  padding: 4px;
}
